<template>
  <div class="info">
    <van-nav-bar title="详情" left-arrow @click-left="onClickLeft" />
    <van-cell-group>
  <van-cell title="户主姓名" :value="info.householder_name" />
  <van-cell title="成员姓名" :value="info.name" />
  <van-cell title="姓别" :value="info.sex==1?'男':'女'" />
  <van-cell title="与户主关系属性" :value="info.householdRelation.name" />
  <van-cell title="年龄" :value="info.age" />
  <van-cell title="现居住地址" :value="info.address" />
  <van-cell title="县内或县外居住" :value="info.in_local==0?'县外居住':'县内居住'" />
  <van-cell title="县外居住地名及原因" :value="info.outside_live_reason==''?'无':info.outside_live_reason" />
  <van-cell title="租赁或自有房" :value="info.house_status==1?'自有房':'租赁'" />
  <van-cell title="联系电话/手机" :value="info.telephone" />
  <van-cell title="已接种/未接种情况" :value="info.vaccination_times==-1?'不能接种':info.vaccination_times==0?'未接种':info.vaccination_times==1?'第一针':info.vaccination_times==2?'第二针':'第三针'" />
  <van-cell title="健康状况" :value="info.health_status==1?'健康':info.health_status==2?'一般':info.health_status==3?'年老':'行动不便'" />
  <van-cell title="所属镇或乡" :value="info.community.name" />
  <van-cell title="所属网格点" :value="info.grid.name" />
  <!-- <van-cell title="所属网格点" :value="info.grid.alias" /> -->
  <!-- <van-cell title="门牌号" :value="info.house_number" /> -->


</van-cell-group>
  </div>
</template>
<script>
import { Icon, NavBar, Toast,Cell, CellGroup } from "vant";
import { myListDetail } from "@/api/apiData";
export default {
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
  },
  data() {
    return {
      info: "",
    };
  },
  mounted() {
    let id = this.$route.query.id;
    myListDetail({ people_id: id }).then((res) => {
      if (res.code == 1) {
        this.info = res.data;
      } else {
        Toast.fail(res.msg);
      }
    });
  },
  methods: {
    onClickLeft() {
      this.$router.push("/list");
    },
  },
};
</script>